import React, { useState, useEffect } from "react";
/* import { useLocation } from "@reach/router"; */
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../src/styles.css";

const IndexPage = ({ location }) => {
  const [loading, setLoading] = useState(false);
  const [tipoPersona, setTipoPersona] = useState("1");
  const [razonSocial, setRazonSocial] = useState(""); // eslint-disable-line
  const [tiposIdentificacion, setTiposIdentificacion] = useState([]);
  const [tipoIdentificacion, setTipoIdentificacion] = useState("1");
  const [identificacion, setIdentificacion] = useState("");
  const [nombres, setNombres] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [telefono, setTelefono] = useState("");
  const [celular, setCelular] = useState("");
  const [ciudades, setCiudades] = useState([]);
  const [ciudad, setCiudad] = useState("");
  const [direccion, setDireccion] = useState("Sin Dirección");
  const [email, setEmail] = useState("");
  const [empresas, setEmpresas] = useState([]);
  const [empresa, setEmpresa] = useState("");
  const [subdominio, setSubdominio] = useState("");
  const [concesionarios, setConcesionarios] = useState(""); // eslint-disable-line
  const [concesionario, setConcesionario] = useState(""); // eslint-disable-line
  const [vitrinas, setVitrinas] = useState([]);
  const [vitrina, setVitrina] = useState("");
  const [vehiculo, setVehiculo] = useState(0);
  const [chasis, setChasis] = useState("");
  const [placa, setPlaca] = useState("");
  const [modelos, setModelos] = useState([]);
  const [modelo, setModelo] = useState(0);
  const [anio, setAnio] = useState("");
  const [fechaVenta, setFechaVenta] = useState("2022-06-13");
  const [kilometraje, setKilometraje] = useState(0);
  const [propietario, setPropietario] = useState(0);
  const [categorias, setCategorias] = useState([]);
  const [categoria, setCategoria] = useState(0);
  const [asunto, setAsunto] = useState("");
  const [detalleCaso, setDetalleCaso] = useState("");
  const [anexo, setAnexo] = useState("");
  const [file, setFile] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  //Leer tipo identificación
  const leerPersona = (e) => {
    setTipoIdentificacion(e.target.value);
  };

  //Leer Ciudad
  const leerCiudad = async (e) => {
    setCiudad(e.target.value);
  };

  //Leer Empresa
  const leerEmpresa = async (e) => {
    setEmpresa(e.target.value);
    const empresaVal = e.target.value;

    const responseVitrinas = await axios.get(
      `https://kiacolombiaapi.pqr.siprok.com.co/api/almacen/get_almacenes/${empresaVal}`
    );
    setVitrinas(responseVitrinas.data);
  };

  //Leer Vitrina
  const leerVitrina = (e) => {
    setVitrina(e.target.value);
  };

  //Leer Vitrina
  const leerVitrinaSub = (e) => {
    const vitrinainfo = e.target.value;
    const infoFinal = vitrinainfo.split("|");
    setVitrina(infoFinal[0]);
    setEmpresa(infoFinal[1]);
  };

  //Leer Vehículo
  const leerVehiculo = async (e) => {
    setVehiculo(e.target.value);
  };

  //Leer Modelo
  const leerModelo = async (e) => {
    setModelo(e.target.value);
  };

  //Leer Categorías
  const leerCategoria = (e) => {
    setCategoria(e.target.value);
  };

  useEffect(() => {
    const url = location.hostname ? location.hostname : "";
    const subdomain = url.split(".");
    setSubdominio(subdomain[0]);

    const getAuth = async () => {
      //Get token to API
      const login = await axios.post(
        "https://kiacolombiaapi.pqr.siprok.com.co/api/auth/login",
        {
          username: "sack2@kia.com.co",
          password: "CM2022",
        }
      );

      const auth = login.data;
      axios.defaults.headers.common["Authorization"] = `Bearer ${auth.token}`;

      if (auth.token.length > 0) {
        const response = await axios.get(
          "https://kiacolombiaapi.pqr.siprok.com.co/api/tipoidentificacion/get_tipos_identificacion/311"
        );
        setTiposIdentificacion(response.data);
        if (tipoPersona === "2") {
          setTipoIdentificacion("2");
        } else {
          setTipoIdentificacion("1");
        }

        const responseCities = await axios.get(
          "https://kiacolombiaapi.pqr.siprok.com.co/api/ciudad/get_ciudad_pqr/1"
        );
        setCiudades(responseCities.data);

        if (subdominio.length > 0) {
          const responseVitrinas = await axios.get(
            `https://kiacolombiaapi.pqr.siprok.com.co/api/almacen/get_almacenes_by_subdominio/${subdominio}`
          );
          setVitrinas(responseVitrinas.data);
        }

        const responseEmpresas = await axios.get(
          "https://kiacolombiaapi.pqr.siprok.com.co/api/empresa/get_empresa_almacen/311"
        );
        setEmpresas(responseEmpresas.data);

        const responseModelos = await axios.get(
          "https://kiacolombiaapi.pqr.siprok.com.co/api/FamilyModel/get_families"
        );
        setModelos(responseModelos.data.data);

        const responseCategorias = await axios.get(
          "https://kiacolombiaapi.pqr.siprok.com.co/api/categoria_vehiculo/categorias_marca/311"
        );
        setCategorias(responseCategorias.data);
      } else {
        console.log("No estás autenticado");
      }
    };
    getAuth();
  }, [tipoPersona, subdominio]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFileSelect = (file) => {
    setFile(file);
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();

    for (let i = 0; i < file.length; i++) {
      formData.append("file", file[i]);
    }

    let archivos = [];

    if (anexo === "1") {
      const response = await axios({
        method: "post",
        url: "https://kiacolombiaapi.pqr.siprok.com.co/api/archivo/upload",
        data: formData,
      });
      archivos = response.data;
    }

    try {
      const respuesta = await axios.post(
        "https://kiacolombiaapi.pqr.siprok.com.co/api/pqr/insert_pqr",
        {
          cliente: {
            cli_tipo_identificacion: parseInt(tipoIdentificacion, 10),
            cli_ruc_cedula: identificacion,
            cli_nombre: nombres,
            cli_apellido: apellidos,
            cli_ciudad: parseInt(ciudad, 10),
            cli_direccion: direccion,
            cli_telefono1: telefono,
            cli_telefono2: celular,
            cli_mail: email,
            cli_tipopersona: parseInt(tipoPersona, 10),
          },
          vehiculo: {
            veh_vin: chasis,
            veh_placa: placa,
            veh_motor: "G4NAKH016024",
            veh_anio: anio,
            veh_kilometraje: parseInt(kilometraje, 10),
            veh_family: parseInt(modelo, 10),
            veh_fecha_venta: fechaVenta,
            /* veh_fecha_venta: "2022-06-13T00:00:00", */
            veh_categoria: parseInt(categoria, 10),
            veh_marca: 311,
          },
          pqr_asunto: asunto,
          pqr_detalle_caso: detalleCaso,
          pqr_solucion_esperada: "",
          pqr_almacen: parseInt(vitrina, 10),
          pqr_canal: 1,
          pqr_empresa: parseInt(empresa, 10),
          pqr_prioridad: 1,
          pqr_tipo_caso: 0,
          pqr_marca: 311,
          pqr_contieneVehiculo: parseInt(vehiculo, 10),
          pqr_cli_poseevehiculo: parseInt(propietario, 10),
          pqr_plataforma: 2,
          archivos: archivos,
        }
      );
      setLoading(false);
      setChasis("");
      setPlaca("");
      e.target.reset();

      Swal.fire({
        title: "Gracias por registrar tu solicitud",
        text: "Pronto nos pondremos en contacto contigo",
        timer: 2000,
        showConfirmButton: false,
        icon: "success",
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <main>
      <Helmet>
        <title>PQR | Kia Colombia </title>
        <meta
          name="keywords"
          content="kia, colombia, pqrs, peticiones, quejas, reclamos"
        />
        <meta
          name="description"
          content="No dudes en escribirnos y déjanos tus datos para poder brindarte atención personalizada lo más pronto posible."
        />
        <meta property="og:title" content="PQR | Kia Colombia" />
        <meta property="og:url" content="https://pqrs.siprok.com.co/" />
        <meta property="og:type" content="article" />
        <meta
          property="og:description"
          content="No dudes en escribirnos y déjanos tus datos para poder brindarte atención personalizada lo más pronto posible."
        />
        <meta
          property="og:image"
          content="https://pqrs.siprok.com.co/banner.png"
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="900" />
      </Helmet>
      <Container className="form-content">
        <Row>
          <Col md={12} className="py-5">
            <Form
              onSubmit={handleSubmit(onSubmit)}
              id="formCotizador"
              className="pqr-form mx-auto"
            >
              <Row className="bg-title">
                <Col md={12}>
                  <h1 className="form-title">Información del solicitante</h1>
                </Col>
              </Row>
              <Row className="form-card">
                <Form.Group className="mb-2" as={Col} md={6}>
                  <Form.Label className="form-label-required">
                    Tipo de persona
                  </Form.Label>
                  <Form.Select
                    required
                    onChange={(e) => setTipoPersona(e.target.value)}
                  >
                    <option value="1">Natural</option>
                    <option value="2">Jurídica</option>
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row>
                <Col md={12} className="bg-title mt-4">
                  <h1 className="form-title">Persona natural</h1>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="bg-title mt-4 d-none">
                  <h1 className="form-title">Persona jurídica</h1>
                </Col>
              </Row>
              <Row className="form-card">
                <Form.Group className="mb-2" as={Col} md={6}>
                  <Form.Label className="form-label-required">
                    Tipo de Identificación
                  </Form.Label>
                  {tipoPersona === "1" ? (
                    <Form.Select required onChange={leerPersona}>
                      {tiposIdentificacion.map((identificacion) => (
                        <option
                          key={identificacion.ti_codigo}
                          value={identificacion.ti_codigo}
                        >
                          {identificacion.ti_descripcion}
                        </option>
                      ))}
                    </Form.Select>
                  ) : (
                    <Form.Select>
                      <option value="2">NIT</option>
                    </Form.Select>
                  )}
                </Form.Group>
                <Form.Group className="mb-2" as={Col} md={6}>
                  <Form.Label className="form-label-required">
                    Identificación
                  </Form.Label>
                  <Form.Control
                    type="text"
                    maxLength="10"
                    {...register("indentificacion", {
                      required: "El número de identificación es requerido",
                      validate: (value) =>
                        value.trim().length > 0 ||
                        "Ingrese un número de identificación válido",
                      maxLength: {
                        value: 10,
                        message:
                          "El número de identificación debe tener máximo 10 caracteres",
                      },
                    })}
                    onChange={(e) => setIdentificacion(e.target.value)}
                  />
                  <p className="validation-message">
                    {errors.indentificacion?.message}
                  </p>
                </Form.Group>

                {tipoPersona === "1" ? (
                  <>
                    <Form.Group className="mb-2" as={Col} md={6}>
                      <Form.Label className="form-label-required">
                        Nombres
                      </Form.Label>
                      <Form.Control
                        type="text"
                        {...register("nombre", {
                          required: "El nombre es requerido",
                          validate: (value) =>
                            value.trim().length > 0 ||
                            "Ingrese un nombre válido",
                          maxLength: {
                            value: 50,
                            message:
                              "Longitud máxima permitida es de 50 caracteres",
                          },
                          pattern: {
                            value:
                              /^[a-zA-ZÀ-ÿ\u00f1\u00d1\u00C0-\u017F ]{3,}$/,
                            message:
                              "No se permiten números ni caracteres especiales",
                          },
                        })}
                        onChange={(e) => setNombres(e.target.value)}
                      />
                      <p className="validation-message">
                        {errors.nombre?.message}
                      </p>
                    </Form.Group>
                    <Form.Group className="mb-2" as={Col} md={6}>
                      <Form.Label className="form-label-required">
                        Apellidos
                      </Form.Label>
                      <Form.Control
                        type="text"
                        {...register("apellido", {
                          required: "El apellido es requerido",
                          validate: (value) =>
                            value.trim().length > 0 ||
                            "Ingrese un apellido válido",
                          minLength: {
                            value: 3,
                            message: "La longitud mínima es de 3 caracteres",
                          },
                          maxLength: {
                            value: 50,
                            message:
                              "Longitud máxima permitida es de 50 caracteres",
                          },
                          pattern: {
                            value:
                              /^[a-zA-ZÀ-ÿ\u00f1\u00d1\u00C0-\u017F ]{3,}$/,
                            message:
                              "No se permiten números ni caracteres especiales",
                          },
                        })}
                        onChange={(e) => setApellidos(e.target.value)}
                      />
                      <p className="validation-message">
                        {errors.apellido?.message}
                      </p>
                    </Form.Group>
                  </>
                ) : (
                  <Form.Group className="mb-2" as={Col} md={6}>
                    <Form.Label className="form-label-required">
                      Razón social
                    </Form.Label>
                    <Form.Control
                      type="text"
                      required
                      onChange={(e) => setRazonSocial(e.target.value)}
                    />
                  </Form.Group>
                )}

                <Form.Group className="mb-2" as={Col} md={6}>
                  <Form.Label>Teléfono</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength="7"
                    {...register("telefono", {
                      minLength: {
                        value: 7,
                        message: "La longitud mínima es de 7 caracteres",
                      },
                      maxLength: {
                        value: 7,
                        message: "Longitud máxima permitida es de 7 caracteres",
                      },
                      pattern: {
                        value: /^[^01]{1}[0-9]{6}$/,
                        message: "Ingrese un teléfono válido",
                      },
                    })}
                    onChange={(e) => setTelefono(e.target.value)}
                  />
                  <p className="validation-message">
                    {errors.telefono?.message}
                  </p>
                </Form.Group>
                <Form.Group className="mb-2" as={Col} md={6}>
                  <Form.Label className="form-label-required">
                    Celular
                  </Form.Label>
                  <Form.Control
                    type="text"
                    maxLength="10"
                    {...register("celular", {
                      required: "El celular es requerido",
                      minLength: {
                        value: 10,
                        message: "La longitud mínima es de 10 caracteres",
                      },
                      maxLength: {
                        value: 10,
                        message:
                          "Longitud máxima permitida es de 10 caracteres",
                      },
                      pattern: {
                        value: /^[3]{1}[0-9]{9}$/,
                        message: "Ingrese un celular válido",
                      },
                    })}
                    onChange={(e) => setCelular(e.target.value)}
                  />
                  <p className="validation-message">
                    {errors.celular?.message}
                  </p>
                </Form.Group>
                <Form.Group className="mb-2" as={Col} md={6}>
                  <Form.Label className="form-label-required">
                    Ciudad
                  </Form.Label>
                  <Form.Select required onChange={leerCiudad}>
                    <option value={""} key={0}>
                      - Seleccione -
                    </option>
                    {ciudades.map((ciudad) => (
                      <option
                        key={ciudad.ciudad_codigo}
                        value={ciudad.ciudad_codigo}
                      >
                        {ciudad.ciudad_nombre}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-2" as={Col} md={6}>
                  <Form.Label>Dirección</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setDireccion(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-2" as={Col} md={6}>
                  <Form.Label className="form-label-required">
                    Correo electrónico
                  </Form.Label>
                  <Form.Control
                    type="email"
                    maxLength="50"
                    {...register("email", {
                      required: "El email es requerido",
                      pattern: {
                        value:
                          /^([A-Za-z0-9_\-\.]+)@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,3})$/, // eslint-disable-line
                        message: "Ingrese un email válido",
                      },
                    })}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <p className="validation-message">{errors.email?.message}</p>
                </Form.Group>

                {subdominio === "kiacolombia" || subdominio === "pqrs" ? (
                  <Form.Group className="mb-2" as={Col} md={6}>
                    <Form.Label className="form-label-required">
                      Concesionario
                    </Form.Label>
                    <Form.Select required onChange={leerEmpresa}>
                      <option value={""} key={0}>
                        - Seleccione -
                      </option>
                      {empresas.map((empresa) => (
                        <option
                          key={empresa.emp_codigo}
                          value={empresa.emp_codigo}
                        >
                          {empresa.emp_razon_social}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                ) : (
                  ""
                )}

                {subdominio === "kiacolombia" || subdominio === "pqrs" ? (
                  <Form.Group className="mb-2" as={Col} md={6}>
                    <Form.Label className="form-label-required">
                      Sede
                    </Form.Label>
                    <Form.Select required onChange={leerVitrina}>
                      <option value={""} key={0}>
                        - Seleccione -
                      </option>
                      {vitrinas.map((vitrina) => (
                        <option
                          key={vitrina.alm_codigo}
                          value={vitrina.alm_codigo}
                        >
                          {vitrina.alm_nombre}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                ) : (
                  <Form.Group className="mb-2" as={Col} md={6}>
                    <Form.Label className="form-label-required">
                      Sede
                    </Form.Label>
                    <Form.Select required onChange={leerVitrinaSub}>
                      <option value={""} key={0}>
                        - Seleccione -
                      </option>
                      {vitrinas.map((vitrina) => (
                        <option
                          key={vitrina.alm_codigo}
                          value={vitrina.alm_codigo + "|" + vitrina.alm_empresa}
                        >
                          {vitrina.alm_nombre}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                )}

                <Form.Group className="mb-2" as={Col} md={6}>
                  <Form.Label>
                    ¿La PQR a registrar está asociada a un vehículo?
                  </Form.Label>
                  <Form.Select required onChange={leerVehiculo}>
                    <option value={""}>Seleccione</option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                  </Form.Select>
                </Form.Group>
              </Row>

              {vehiculo === "1" && (
                <>
                  <Row className="bg-title mt-4">
                    <Col md={12}>
                      <h1 className="form-title">Información del vehículo</h1>
                    </Col>
                  </Row>
                  <Row className="form-card">
                    <Form.Group className="mb-2" as={Col} md={6}>
                      <Form.Label>Chasis</Form.Label>
                      <Form.Control
                        type="text"
                        maxLength="32"
                        value={chasis.toUpperCase()}
                        {...register("chasis", {
                          pattern: {
                            value: /^[A-Z0-9]{10,50}$/,
                            message: "Ingrese un chasis válido",
                          },
                        })}
                        onChange={(e) =>
                          setChasis(e.target.value.toUpperCase())
                        }
                      />
                      <p className="validation-message">
                        {errors.chasis?.message}
                      </p>
                    </Form.Group>
                    <Form.Group className="mb-2" as={Col} md={6}>
                      <Form.Label className="form-label-required">
                        Placa
                      </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength="10"
                        value={placa.toUpperCase()}
                        {...register("placa", {
                          required: "La placa es requerida",
                          pattern: {
                            value: /^[A-Z]{3,4}[0-9]{3,4}$/,
                            message: "Ingrese una placa válida",
                          },
                        })}
                        onChange={(e) => setPlaca(e.target.value.toUpperCase())}
                      />
                      <p className="validation-message">
                        {errors.placa?.message}
                      </p>
                    </Form.Group>
                    <Form.Group className="mb-2" as={Col} md={6}>
                      <Form.Label className="form-label-required">
                        Modelo
                      </Form.Label>
                      <Form.Select required onChange={leerModelo}>
                        <option value={""} key={0}>
                          - Seleccione -
                        </option>
                        {modelos.map((modelo) => (
                          <option key={modelo.gfm_code} value={modelo.gfm_code}>
                            {modelo.gfm_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-2" as={Col} md={6}>
                      <Form.Label>Año modelo</Form.Label>
                      <Form.Control
                        type="text"
                        maxLength="4"
                        {...register("anio", {
                          pattern: {
                            value: /^(19|[2-9][0-9])\d{2}$/,
                            message: "Ingrese un año válido",
                          },
                        })}
                        onChange={(e) => setAnio(e.target.value)}
                      />
                      <p className="validation-message">
                        {errors.anio?.message}
                      </p>
                    </Form.Group>
                    <Form.Group className="mb-2" as={Col} md={6}>
                      <Form.Label>Fecha de venta</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={(e) => setFechaVenta(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2" as={Col} md={6}>
                      <Form.Label className="form-label-required">
                        Kilometraje
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        maxLength="10"
                        onChange={(e) => setKilometraje(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-2" as={Col} md={6}>
                      <Form.Label className="form-label-required">
                        ¿El solicitante es el propietario del vehículo?
                      </Form.Label>
                      <Form.Select
                        required
                        onChange={(e) => setPropietario(e.target.value)}
                      >
                        <option value={""}>Seleccione</option>
                        <option value="1">Si</option>
                        <option value="0">No</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-2" as={Col} md={6}>
                      <Form.Label className="form-label-required">
                        Tipo de uso
                      </Form.Label>
                      <Form.Select required onChange={leerCategoria}>
                        <option value={""} key={0}>
                          - Seleccione -
                        </option>
                        {categorias.map((categoria) => (
                          <option
                            key={categoria.cat_codigo}
                            value={categoria.cat_codigo}
                          >
                            {categoria.cat_descripcion}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Row>
                </>
              )}

              <Row className="bg-title mt-4">
                <Col md={12}>
                  <h1 className="form-title">Detalle del caso</h1>
                </Col>
              </Row>
              <Row className="form-card">
                <Form.Group className="mb-2" as={Col} md={6}>
                  <Form.Label className="form-label-required">
                    Asunto
                  </Form.Label>
                  <Form.Control
                    required
                    maxLength="200"
                    type="text"
                    onChange={(e) => setAsunto(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-2" as={Col} md={12}>
                  <Form.Label className="form-label-required">
                    Detalle del caso
                  </Form.Label>
                  <Form.Control
                    required
                    maxLength="200"
                    as="textarea"
                    rows={2}
                    onChange={(e) => setDetalleCaso(e.target.value)}
                  />
                </Form.Group>
              </Row>
              <Row className="bg-title mt-4">
                <Col md={12}>
                  <h1 className="form-title">Anexo</h1>
                </Col>
              </Row>
              <Row className="form-card">
                <Form.Group className="mb-2" as={Col} md={6}>
                  <Form.Label className="form-label-required">
                    ¿Desea anexar documento?
                  </Form.Label>
                  <Form.Select
                    required
                    onChange={(e) => setAnexo(e.target.value)}
                  >
                    <option value="">Seleccione</option>
                    <option value="1">Si</option>
                    <option value="2">No</option>
                  </Form.Select>
                </Form.Group>

                {anexo === "1" && (
                  <Form.Group className="mb-2" as={Col} md={12}>
                    <Form.Label>Adjuntar anexo</Form.Label>
                    <Form.Control
                      type="file"
                      required
                      multiple
                      onChange={(e) => handleFileSelect(e.target.files)}
                    />
                  </Form.Group>
                )}
              </Row>
              <Row>
                <Col md={12} className="mt-3">
                  {subdominio === "greccomotors" ? (
                    <Form.Group className="d-flex">
                      <Form.Check type="checkbox" required />
                      <p className="terminos-condiciones ps-2 pe-3 pt-1">
                        Autorización para formatos de gestión de PQRS:
                        BRACHOAUTOS S.A.S - sociedad identificada con NIT.
                        804.014.559-2 en calidad de Responsable del tratamiento
                        de sus datos personales, datos de identificación y de
                        contacto, solicita su autorización para dar tratamiento
                        a los datos personales de contacto que suministre a
                        través de la presente. Estos datos serán utilizados con
                        la finalidad de mantener una comunicación efectiva
                        orientada a solicitar mayor información de ser necesaria
                        para atender el requerimiento realizado por usted,
                        verificar posteriormente la percepción de éstos frente a
                        las soluciones propuestas, las medidas de mejoramiento
                        adoptadas, enviar comunicaciones de prospección
                        comercial y fidelización de clientes y remitir respuesta
                        frente a las PQRS presentadas. Nuestra Política de
                        Tratamiento de Información Personal puede ser consultada
                        en{" "}
                        <a href="www.greccomotors.com">www.greccomotors.com</a>{" "}
                        y sus derechos como titular (acceso, rectificación,
                        cancelación, revocación y supresión) de datos personales
                        podrán ser ejercidos a través{" "}
                        <a href="mailto:protecciondedatos@greccomotors.com">
                          protecciondedatos@greccomotors.com
                        </a>
                      </p>
                    </Form.Group>
                  ) : (
                    <div className="d-flex justify-content-center mt-1">
                      <div className="text-center terminos-condiciones">
                        <Form.Group>
                          <Form.Check
                            id="terminos"
                            type="checkbox"
                            label="Acepto términos y condiciones y la política de tratamiento de datos."
                            required
                          />
                        </Form.Group>
                        <p className="terminos-condiciones text-center">
                          Para más información consulta Términos y condiciones.
                        </p>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={12} className="text-center">
                  {!loading && (
                    <Button
                      variant="dark"
                      type="submit"
                      className="rounded-0 mt-2"
                      id="btn-enviar"
                    >
                      Radicar PQR
                    </Button>
                  )}
                  {loading && (
                    <Button
                      variant="dark"
                      type="submit"
                      className="rounded-0"
                      id="btn-enviar"
                      disabled
                    >
                      <FontAwesomeIcon icon={faSpinner} className="fa-spin" />{" "}
                      Enviando...
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default IndexPage;
